(function(){
     'use strict';

     angular
        .module('app')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$http'];

    function LoginService($http){


        var service= {
           login:login,
           session:session,
           save:save,
        };

        return service;
 		function login(vm,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }

            var data = $.param({ RegisterForm : {
               email:vm.email,
               pincode:vm.pass
           	}
            });
            $http.post('/site/register', data, config)
            .success(getContentSuccess)
            .error(getContentFailed);
            function getContentSuccess(data){ 
                callback(data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
        function session(callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            $http.post('/site/getsession', {}, config)
            .success(getContentSuccess)
            .error(getContentFailed);
            function getContentSuccess(data){ 
                callback(data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }

        function save(user,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            $http.post('/site/save', $.param({ data : user}), config)
            .success(getContentSuccess)
            .error(getContentFailed);
            function getContentSuccess(data){ 
                callback(data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }

    }
})();