(function(){
    angular
        .module('app')
        .directive('selectBoxit', selectBoxit);

    selectBoxit.$inject = ['$timeout', '$parse','$rootScope']

    function selectBoxit($timeout, $parse,$rootScope){
        return {
            require: 'ngModel',
            scope: {watch: '='},
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);
                    
                    $element.selectBoxIt();

                    
                    scope.$watch('watch', function (newValue) {
                        console.lo
                        $timeout(function () {$element.data("selectBox-selectBoxIt").refresh();});
                    });
                    scope.$watch($attrs.ngModel, function (newValue) {
                        
                        console.lo
                        $timeout(function () {$element.data("selectBox-selectBoxIt").refresh();});
                    });
                    scope.$on('reload',function(event,arg){
                        console.log("reload");
                        $timeout(function () {$element.data("selectBox-selectBoxIt").refresh();});

                    })

                    // If the iCheck control changes, update the model.
                    $element.on('change', function (event) {
                        
                        
                          scope.$apply(function () {
                                ngModel.$setViewValue($element.val());
                                $rootScope.$broadcast('reload',{el:$element});
                            });
                        

                    });

                });
            }
        };
    }
})();