(function() {
    'use strict';

    angular.module('app',['ngSanitize','ngRoute','ui.bootstrap']).config(function($httpProvider) {

            // $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            $httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
        }).config(function ($routeProvider) {
        $routeProvider
            .when("/", {
                templateUrl: 'app/views/intro.html', 
                controller: 'HomeController', 
                navTab: "home"
            })
            .when("/quiero-un-10", {
                templateUrl: 'app/views/quiero10.html', 
                controller: 'AccesoController', 
                navTab: "acceso"
            })
            .when("/registro", {
                templateUrl: 'app/views/registro.html', 
                controller: 'RegistroController', 
                navTab: "registro"
            })
            /*.when("/codigo-itunes", {
                templateUrl: 'app/views/codigo-itunes.html', 
                controller: 'itunesController', 
                navTab: "itunes"
            })
            .when("/codigo-googleplay", {
                templateUrl: 'app/views/codigo-googleplay.html', 
                controller: 'gplayController', 
                navTab: "googleplay"
            })*/
            .otherwise({redirectTo: '/'})
        ;
    });

})();
