(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('AccesoController', AccesoController)
 
    AccesoController.$inject = ['LoginService','$timeout','$location',"$uibModal"];
   
    function AccesoController(LoginService,$timeout,$location,$uibModal)
    {                        
        var vm = this; 
        vm.email = "";
        vm.pass = "";
        vm.loading = 0;
        vm.init = function(){
            LoginService.session(function(data){
                $timeout(function(){
                   if(typeof data.data!= 'undefined'){
                     $location.path("registro");
                    //console.log(vm.user);
                   }
                })
            });
        }
        vm.msgKO = "";
        vm.login = function(){
            vm.loading = 1;
        	LoginService.login(vm,function(data){
                vm.loading = 0;
                $timeout(function(){
                   if(data.Result.status==200){
                        $location.path("registro");
                   }else{
                        vm.msgKO = $uibModal.open({
                          animation: true,
                          ariaLabelledBy: 'modal-title-top',
                          ariaDescribedBy: 'modal-body-top',
                          templateUrl: 'msgKO.html',
                          size: 'lg',
                          controller: function($scope) {
                            $scope.okButton = function(){
                                vm.msgKO.close();
                            } 
                          }
                      });
                   }
                })
            });
        }
        
        
    }

})();