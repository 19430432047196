(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('RegistroController', RegistroController)
 
    RegistroController.$inject = ['LoginService','$timeout',"$uibModal"];
   
    function RegistroController(LoginService,$timeout,$uibModal)
    {                        
        var vm = this; 
        vm.msgOK = "";
        vm.loading = 0;
        vm.update = function(user){
            vm.loading = 1;
        	LoginService.save(user,function(data){
                vm.loading = 0;
                vm.msgOK = $uibModal.open({
                  animation: true,
                  ariaLabelledBy: 'modal-title-top',
                  ariaDescribedBy: 'modal-body-top',
                  templateUrl: 'datosOK.html',
                  size: 'lg',
                  controller: function($scope) {
                    $scope.okButton = function(){
                        vm.msgOK.close();
                    } 
                  }
              });
            });
        }
        
        
    }

})();