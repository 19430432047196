(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('AppController', AppController)
 
    AppController.$inject = ['LoginService','$timeout'];
   
    function AppController(LoginService,$timeout)
    {                        
        var vm = this; 
        vm.user = {};
        LoginService.session(function(data){
            $timeout(function(){
               if(typeof data.data!= 'undefined'){
               	vm.user = data.data;
               	//console.log(vm.user);
               }
            })
        });
    }

})();